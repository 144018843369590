.ticker_wrapper {
  width: 100%;
  overflow: hidden;

  position: relative;
  margin-top: 10rem;

}

.ticker {
  display: flex;
  width: max-content;
  animation: ticker-animation 180s linear infinite;
  gap: 3.5rem;
  animation-play-state: running;
  margin-top: 3rem;
  background: white;
  padding-top: 2rem;
}

.ticker-item {
  flex-shrink: 0;
  margin-right: 20px;
  /* Adjust margin */
}

.ticker_wrapper:hover .ticker {
  animation-play-state: paused;
}

@keyframes ticker-animation {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-50%);
    /* Move left by 50% to loop without gaps */
  }
}

.ticker img {
  width: auto;
  /* Adjust as needed */
  height: 60px;
  /* Maintain consistent height */
  display: block;
}


.metrics_container {
  width: 100vw;
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* flex-wrap: wrap; */
  gap: 2rem;
  padding: 1rem 2rem;
  /* background: #FF7700; */
  background: linear-gradient(180deg, #B6B6B6 0%, rgba(217, 217, 217, 0) 100%);

  position: absolute;
  /* top: -76px; */
  left: 0;
  right: 0;
}

.metrics_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* max-width: 350px; */
  max-width: 250px;
  /* min-width: 200px; */
  height: 100%;
  width: 100%;
  border-radius: 0.5rem;
  /* background: #ff7700; */
  background: white;
  /* box-shadow: 0px 5px 7px 0px rgba(0,0,0,0.8); */
  box-shadow: 4px 4px 9px 0px rgba(0, 0, 0, 0.4);
  top: -76px;
  position: relative;
  transition: transform 0.3s ease;
}

.metrics_title {
  font-size: 43px;
  font-weight: 700;
  color: #FFF;
  margin-bottom: -10px !important;
}

.metrics_body {
  font-size: 1rem;
  font-weight: 600;
  color: #FFF;
  text-align: center;
  max-width: 180px;
}

.tileImage {
  height: 90px;
  margin-top: 1rem;
}

.metrics_box:hover {
  transform: translateY(-10px);
  /* cursor: pointer; */
}

.extraborder {
  border: 0px solid #ff7700;
  width: inherit;
  height: inherit;
  border-radius: 10px 10px 0px 0px;
  padding-bottom: 8px;
  background-color: fff;
  border-bottom: none;
  /* border-right: 1px solid #ff7700;
    border-left: 1px solid #ff7700; */
}

.contentBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px 10px 0px 0px !important;
  background: linear-gradient(180deg, #FF7700 0%, #EC6A00 100%);
  min-height: inherit;
  width: inherit;
}

@media (min-width: 801px) {
  .metrics_container {
    flex-wrap: nowrap;
  }

}

@media (max-width:1600px) {
  .metrics_box {
    min-height: 225px;
  }
}


@media (max-width: 1200px) {
  .uneven-column {
    margin-top: 32px;
  }
}

@media (max-width: 1150px) {
  .main_outer_container {
    padding: 3rem 4rem;
  }

  .metrics_box {
    min-height: 225px;
  }
}


@media (max-width: 1100px) {
  .metrics_box {
    top: -57px;
  }

}



@media (max-width: 1000px) {
  /* .metrics_container{
      padding: 1rem 3vw;
      gap: 1rem;
    } */

  .metrics_title {
    font-size: 35px;
  }

  .metrics_body {
    font-size: 0.75rem;
  }

  .metrics_box {
    min-height: 201px;
  }
}

/* @media (max-width:950px) {
    .metrics_box{
      min-height: 105px;
    }
  } */

@media (max-width: 900px) {
  .metrics_container {
    padding: 1rem 3vw;
    gap: 1rem;
  }
}

@media (max-width: 800px) {
  .metrics_container {
    padding: 1rem 2vw;
    gap: 0.5rem;
  }

  .metrics_title {
    font-size: 30px;
  }

  .metrics_box {
    min-height: 191px;
  }
}

@media (max-width: 750px) {
  .tileImage {
    height: 60px;
    margin-top: 1rem;
  }

  .metrics_box {
    min-height: 162px;
  }
}

@media (max-width: 580px) {
  .metrics_container {
    flex-wrap: wrap;
  }

  .metrics_box {
    top: 0px;
    margin-top: 1rem;
  }

  .ticker_wrapper {
    margin-top: 23rem;
  }
}

@media (max-width:530px) {
  .metrics_box {
    max-width: 165px;
    min-height: 153px;
  }

  .tileImage {
    height: 45px;
  }

  .metrics_title {
    font-size: 27px;
    margin-bottom: 0px !important;
  }
}

@media (max-width: 353px) {
  .metrics_box {
      max-width: 150px;
      min-height: 153px;
  }
}
@media (min-width:1500px) {
  .metrics_box {
    min-height: 231px;
}

}